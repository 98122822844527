@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400|Barlow:300,400,500,600);
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* This depends on the 3rd party rule(Gigya IdpManager) */
#idp-modal-wrapper {
  font-size: initial;
  color: initial;
}

#idp-modal-wrapper input {
  box-sizing: content-box;
}

.gigya-react-modal-main {
  height: auto;
  bottom: -50px;
}

/* PS5 video containers */
#mediasdk-player {
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.sce-uvp-player-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.sce-uvp-player-container video {
  width: 100%;
  height: 100%;
}

